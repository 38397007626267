import axios, { reLogin } from '../axios'

/**
 * @param {object} params
 * @param {string} author
 * @param {string} content
 * @param {string} files
 * @param {string} title
 */
async function newsCreate (params) {
  if (!params) return null
  try {
    const { data } = await axios.post('/news/create', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {object} params
 * @param {string} pn
 * @param {string} ps
 * @param {string} keyword
 */
async function newsList (params) {
  if (!params) return null
  try {
    const { data } = await axios.get('/news/list', { params })
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 *
 * @param {object} params
 * @param {string} author
 * @param {string} content
 * @param {string} files
 * @param {0} id
 * @param {string} title
 */
async function newsPut (params) {
  if (!params) return null
  try {
    const { data } = await axios.put('/news/put', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {number} id
 */
async function newsSend (id) {
  if (!id) return null
  try {
    const { data } = await axios.post(`/news/send/${id}`)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {number} id
 */
async function newsDelete (id) {
  if (!id) return null
  try {
    const { data } = await axios.delete(`/news/${id}`)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}

export {
  newsCreate,
  newsList,
  newsPut,
  newsSend,
  newsDelete
}
